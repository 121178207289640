<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="960px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="auto">
      <el-form-item :label="$t('task.taskName')" prop="name">
        <el-input
          v-model="form.name"
          :placeholder="$t('task.taskNamePla')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('task.taskTime')" prop="beginTime">
        <el-date-picker
          v-model="form.beginTime"
          type="datetime"
          value-format="timestamp"
          :clearable="false"
          :picker-options="pickerOptions"
          style="width: 100%"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="footer-btns">
      <el-button class="footer-btn" type="primary" plain @click="handleClose">{{ $t('cancel') }}</el-button>
      <el-button class="footer-btn" type="primary" @click="submit">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  pushConfigModelSchedule,
  pushConfigGroupSchedule,
  pushConfigSiteSchedule,
} from '@/api/setParam.js'
import { firmwareUpgrade2 } from '@/api/firmware'
import { resourcesUpgrade2 } from '@/api/otherResources'
import { pushSuccessMassage } from '@/plugins/methods'

export default {
  name: 'dialog-scheduled-update',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default() {
        return this.$t('scheduledConfigurationUpdate')
      },
    },
    req: Object,
  },
  data() {
    return {
      form: {
        name: '',
        beginTime: new Date().getTime() + (10 * 60 * 1000),
      },
      rules: {
        name: {
          required: true,
          message: this.$t('task.taskNameRule'),
          trigger: 'blur',
        },
        beginTime: {
          required: true,
          validator: (rule, value, callback) => {
            const nowTime = parseInt(new Date().getTime()/1000)*1000
            let legalTime = new Date().getTime() + (5 * 60 * 1000)
            if(nowTime == value) {
              this.form.beginTime = value + (10 * 60 * 1000)
            } else if(value - legalTime < 0) {
              callback(new Error(this.$t('task.taskBeginTimeRule')))
            } else {
              callback()
            }
          },
          trigger: 'change',
        },
      },
      pickerOptions: {
        disabledDate(time) { return time.getTime() < Date.now() - 8.64e7 }
      }
    }
  },
  methods: {
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 确定
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const params = {
            name: this.form.name,
            beginTime: this.form.beginTime,
            taskParam: this.req.data.params.taskParam,
            template: this.req.data.params.taskParam,
            deviceType: this.req.data.params.deviceType,
            type: 2,
          }
          if (this.req.data.params.deviceType == 1) {
            params.deviceIds = this.req.data.params.deviceIds
          } else {
            params.macs = this.req.data.params.macs
          }
          const pushDeviceApi = {
            model: pushConfigModelSchedule,
            group: pushConfigGroupSchedule,
            site: pushConfigSiteSchedule,
            firmware: firmwareUpgrade2,
            otherResources: resourcesUpgrade2,
          }
          pushDeviceApi[this.req.data.relationName](params)
            .then(() => {
              pushSuccessMassage(this)
              // this.$message.success(data.message)
              this.handleClose()
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
