import axios from '@/plugins/axios.js'

// 查询版本数据
export const resourcesSearch = (params) => {
  let { keywords, site, ...newParams } = params
  newParams.keywords = keywords == null ? '' : keywords
  newParams.site = site == '' ? null : [params.site]
  return axios.post('/tb-resources/selectResources', newParams)
}

// 推送配置
export const resourcesUpgrade2 = (params) => {
  const now = new Date(new Date().toLocaleDateString()).getTime()
  const newParams = {
    type: params.type,
    name: params.name,
    resourceId: params.template,
    beginTime: params.beginTime ? params.beginTime : now,
    endTime: params.endTime ? params.endTime : now + 86399000,
    deviceIds: params.deviceIds,
    macs: params.macs,
    deviceType: params.deviceType
  }
  return axios.post('/tb-resources/push', newParams)
}

// 查询单条版本数据
export const resourcesQuery = (params) =>
  axios.get('/tb-resources/selectOtherResourcesById', { params })

// 添加或修改版本数据
export const addResources = (params) => axios.post('/tb-resources/addOrEditResource', params)

// 删除版本数据
export const resourcesRemoveBatch = (params) =>
  axios.post('/tb-resources/deleteResources', params )

// 下载配置文件
export const resourcesDownLoad = (params) =>
  axios.post('/tb-resources/downLoadFile', params, { responseType: 'blob' })
