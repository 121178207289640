<template>
  <el-dialog
    :title="$t('model.pushModelConfiguration')"
    :visible.sync="visible"
    width="70%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item prop="deviceType">
        <el-radio-group v-model="form.deviceType">
          <el-radio :label="1">{{ $t('task.selectDevice') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <select-device
          ref="selectDevice"
          :columnData="columnData"
          :modelOptions="modelOptions"
          :macs.sync="form.macs"
          :devices.sync="devices"
          :deviceType.sync="form.deviceType"
          :toolsDisplay="toolsDisplay"
        ></select-device>
      </el-form-item>
    </el-form>
    <span slot="footer" class="footer-btns">
      <el-button class="footer-btn" type="primary" plain @click="handleClose">{{ $t('cancel') }}</el-button>
      <el-button class="footer-btn" type="primary" @click="update('now')">{{ $t('updateNow') }}</el-button>
      <el-button class="footer-btn" type="primary" @click="update('scheduled')">{{
        $t('scheduledConfigurationUpdate')
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { SelectDevice } from '@/components'
import { pushConfigModel, pushConfigGroup, pushConfigSite } from '@/api/setParam.js'
import { firmwareUpgrade2 } from '@/api/firmware'
import { resourcesUpgrade2 } from '@/api/otherResources'
import { groupQuery } from '@/api/group'
import { getDevice } from '@/api/device'
import { pushSuccessMassage } from '@/plugins/methods'

export default {
  name: 'dialog-configuration-push',
  components: {
    SelectDevice,
  },
  props: {
    type: String,
    visible: {
      type: Boolean,
      default: false,
    },
    // title: {
    //   type: String,
    //   default() {
    //     return this.$t('model.pushModelConfiguration')
    //   },
    // },
    columnData: {
      type: Array,
      default() {
        return [
          {
            label: this.$t('device.macAddress'),
            prop: 'macAddress',
            minWidth: 150,
            filter: 'toUpperCase',
          },
          {
            label: this.$t('device.deviceName'),
            prop: 'deviceName',
            minWidth: 150,
          },
          {
            label: this.$t('sipAccount.userName'),
            prop: 'userName',
            minWidth: 120,
            filterEnable: true,
          },
        ]
      },
    },
    modelOptions: Array,
    req: Object,
    toolsDisplay: Object,
  },
  data() {
    return {
      devices: [],
      form: {
        deviceType: 1,
        macs: [],
        deviceIds: [],
      },
      rules: {},
    }
  },
  watch: {
    devices(val) {
      this.form.macs = val.map((e) => e.macAddress)
      this.form.deviceIds = val.map((e) => e.id)
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.getDervice()
      let { model, site } = this.req.row
      //console.log("init model=%s site=%s", model, site)
      if(this.type == 'model') {
        this.$refs.selectDevice.tabelSearchChange({ model, site })
      } else if(this.type == 'site') {
        this.$refs.selectDevice.tabelSearchChange({ site })
      }
    },
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 确定
    update(type) {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const params = {
            taskParam: this.req.row.id,
            template: this.req.row.id,
            deviceType: this.form.deviceType,
            type: 1,
          }
          if (this.form.deviceType == 1) {
            params.deviceIds = this.form.deviceIds
          } else {
            params.macs = this.form.macs
          }
          const pushDeviceApi = {
            model: pushConfigModel,
            group: pushConfigGroup,
            site: pushConfigSite,
            firmware: firmwareUpgrade2,
            otherResources: resourcesUpgrade2,
          }
          if (type == 'now') {
            pushDeviceApi[this.req.row.relationName](params)
              .then(() => {
                pushSuccessMassage(this)
                // this.$message.success(data.message)
                this.handleClose()
              })
              .catch(() => {})
          } else {
            this.handleClose()
            this.$emit('scheduled', { params, relationName: this.req.row.relationName })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 获取设备
    getDervice() {
      // console.log(this.type, this.req.row)
      switch (this.type) {
        case 'group':
          return groupQuery({ id: this.req.row.id })
            .then(({ data }) => {
              const { devices } = data.result.rows[0]
              this.devices = devices
            })
            .catch(() => {})
          // break
        case 'model':
          return getDevice({ model: this.req.row.model[0], site: this.req.row.site })
            .then(({ data }) => {
              this.devices = data.result.rows[0]
            })
            .catch(() => {})
          // break
        case 'site':
          return getDevice({ site: this.req.row.site })
            .then(({ data }) => {
              this.devices = data.result.rows[0]
            })
            .catch(() => {})
          // break
      }
    },
  },
}
</script>
